import React, {useEffect} from "react"
import Layout from "../components/layout"
import parse from "html-react-parser"
import {SearchResultsItem, SearchResults} from "../interfaces/search"
import {SEO} from "../components/seo"
import {HeadFC} from "gatsby"

const SearchPage = (): JSX.Element => {
  const urlParams: string =
    typeof window !== "undefined" ? window.location.search : ""
  const cx = "000657604716479185735:qsjaup6t4wy" // Google Search engine ID
  const APIkey = "AIzaSyDizOowU25xS_R72ejtVZMTlmJD1AVfzR0" // Google API key
  const query: string = new URLSearchParams(urlParams).get("q") || "" // Get the search query from the URL

  const start: string = new URLSearchParams(urlParams).get("start") || "1"
  const [results, setResults] = React.useState<SearchResults>()
  const [error, setError] = React.useState<string>()

  useEffect(() => {
    const params = new URLSearchParams()
    params.append("q", query)
    params.append("cx", cx)
    params.append("key", APIkey)
    params.append("start", start)
    fetch(`https://www.googleapis.com/customsearch/v1?${params.toString()}`)
      .then(response => response.json())
      .then(result => {
        if (result.error) {
          setError(result.error.message)
        } else {
          setResults(result)
        }
      })
      .catch(error => {
        setError(error.message)
      })
  }, [])

  useEffect(() => {
    error && console.error(error)
  }, [error])

  const searchResultMessage = (): JSX.Element => {
    // Google Custom Search returns a maximum of 100 results
    // So, navigating to the 11th page will return an error
    // TODO: Fix this bug
    if (results && results?.queries) {
      if (results.searchInformation?.totalResults === "0") {
        return (
          <p>
            De zoekterm <b>{query}</b> heeft geen resultaten. Zorg dat de
            zoekterm correct is gespeld of probeer een andere zoekterm.
          </p>
        )
        //// Suggestion
        // } else if (parseInt(results.searchInformation?.totalResults) > 100) {
        //   return (
        //     <p>
        //       De zoekterm <b>{query}</b> heeft meer dan 100 resultaten. De eerste
        //       100 resultaten worden getoond.
        //     </p>
        //   );
      } else {
        return (
          <p>
            {results.searchInformation?.totalResults} resultaten gevonden voor{" "}
            {query}
          </p>
        )
      }
    } else if (error) {
      return (
        <p>Zoeken is tijdelijk niet beschikbaar. Probeer het later opnieuw.</p>
      )
    } else {
      return <p>Bezig met zoeken...</p>
    }
  }

  return (
    <Layout currentLocation="">
      <div className="search">
        <h1>Zoeken</h1>

        {/* Searchbar */}
        <form action="/search" method="get">
          <div>
            <input id="q" type="text" name="q" defaultValue={query} />
          </div>
          <div>
            <input type="submit" value="Zoeken" tabIndex={2} />
          </div>
        </form>

        {/* Search results */}
        {searchResultMessage()}

        {results?.items && (
          <>
            <div className="results">
              {results.items.map((result: SearchResultsItem) => {
                return (
                  <div key={result.link}>
                    <h3>
                      <a href={result.link}>{result.title}</a>
                    </h3>
                    <small>{parse(result.htmlFormattedUrl)}</small>
                    <p>{parse(result.htmlSnippet)}</p>
                  </div>
                )
              })}
            </div>

            {/* Pagination */}
            <p className="page">
              {results.queries.previousPage && (
                <a
                  className="previous"
                  href={`/search?q=${query}&start=${results.queries.previousPage[0].startIndex}`}
                >
                  vorige pagina
                </a>
              )}

              <span>
                Resultaten{" "}
                <b>
                  {results.queries.request[0].startIndex} -{" "}
                  {results.queries.request[0].startIndex +
                    results.queries.request[0].count -
                    1}
                </b>{" "}
                van <b>{results.searchInformation.formattedTotalResults}</b>
              </span>

              {results.queries.nextPage && (
                <a
                  className="next"
                  href={`/search?q=${query}&start=${results.queries.nextPage[0].startIndex}`}
                >
                  volgende pagina
                </a>
              )}
            </p>
          </>
        )}
      </div>
    </Layout>
  )
}

export default SearchPage

export const Head: HeadFC = () => <SEO title="Zoeken" />
